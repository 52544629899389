import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { shiftSchema } from "@src/appV2/Shifts/Shift/types";
import { z } from "zod";

const openShiftByDateRequestSchema = z.object({
  dateFilter: z.object({
    start: z.string(),
    end: z.string(),
  }),
  coordinates: z.tuple([z.number(), z.number()]),
  distance: z.number(),
  qualification: z.string(),
  specialities: z.object({
    hasSedationExperience: z.boolean(),
    hasTrayAssemblyExperience: z.boolean(),
  }),
  // MHR = maximum hours restrictions, a.k.a can't book more than X hours per week / Y consecutive hours.
  // When this is set to true we don't filter away such shifts even if they aren't bookable
  includeMHRRestrictedShifts: z.boolean().optional(),
});

const openShiftByDateResponseSchema = z.object({
  openAgentShifts: z.array(
    shiftSchema.extend({
      filtered: z.boolean().nullish(),
    })
  ),
});

export type OpenShiftRequestParams = z.infer<typeof openShiftByDateRequestSchema>;
export type OpenShiftsByDateResponse = z.infer<typeof openShiftByDateResponseSchema>;

export const GET_OPEN_SHIFT_BY_DATE_PATH = "/calendar/openShifts";
export const GET_OPEN_SHIFT_BY_DATE_URL = `${environmentConfig.REACT_APP_BFF_URL}${GET_OPEN_SHIFT_BY_DATE_PATH}`;

export function useOpenShiftsByDate(
  params: OpenShiftRequestParams,
  options: UseGetQueryOptions<OpenShiftsByDateResponse> = {}
) {
  return useGetQuery({
    url: GET_OPEN_SHIFT_BY_DATE_URL,
    queryParams: params,
    requestSchema: openShiftByDateRequestSchema,
    responseSchema: openShiftByDateResponseSchema,
    meta: {
      userErrorMessage: "Something went wrong while loading shifts",
      logErrorMessage: APP_V2_APP_EVENTS.GET_OPEN_SHIFT_BY_DATE_FAILURE,
    },
    ...options,
  });
}
